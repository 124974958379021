<template>
  <div id="Withdraw">
    <div id="Withdraw-form">
      <a-form-model
        ref="formModel"
        :model="formModel.item"
        :rules="formModel.rules"
        v-bind="{
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        }"
      >
        <a-form-model-item label="可提资产">
          <p :style="{ fontSize: '1.5em', letterSpacing: '.1em' }">
            {{ currencyInfo.balance_str }}
          </p>
        </a-form-model-item>
        <a-form-model-item label="提币标签" prop="a">
          <a-select
            v-model="formModel.item.a"
            :placeholder="'提往哪个钱包或交易所'"
          >
            <a-select-option value="火币网">火币网</a-select-option>
            <a-select-option value="比特儿">比特儿</a-select-option>
            <a-select-option value="币安">币安</a-select-option>
            <a-select-option value="imToken">imToken</a-select-option>
            <a-select-option value="其他">其他</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="b">
          <template slot="label">
            <span>提币地址</span>
            <!-- <template v-if="!currencyInfo.blockchain_address || true">
              <a-button
                size="small"
                :style="{ marginLeft: '1em', width: 'auto' }"
              >
                <router-link
                  :to="{
                    name: `Setting-Extract-Currency-Address`,
                    query: { currency: $route.params.type },
                  }"
                >
                  设置提币地址
                </router-link>
              </a-button>
            </template> -->
          </template>
          <p
            :style="{
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              margin: 0,
              lineHeight: '1.5em',
              padding: '.5em 0',
            }"
          >
            {{ currencyInfo.blockchain_address }}
          </p>
        </a-form-model-item>
        <a-form-model-item label="提币数量" prop="c">
          <a-input
            autocomplete="off"
            type="number"
            v-model="formModel.item.c"
            oninput="value=value.replace(/[^\d.]/g,'')"
            :suffix="$route.params.type"
            :placeholder="`最小提币数量${
              currencyInfo.withdraw_min_quantity
                ? currencyInfo.withdraw_min_quantity
                : 0
            }
            `"
            @blur="input_num_control"
          >
            <template slot="addonAfter">
              <a-button>
                <a @click="formModel.item.c = currencyInfo.balance.toFixed(6)">
                  全部
                </a>
              </a-button>
            </template>
          </a-input>
          <span slot="help">
            <p
              :style="{ color: '#f5222d' }"
              v-if="currencyInfo.balance < currencyInfo.withdraw_min_quantity"
            >
              您当前可提现资产不足最小提币数量
            </p>
            <span>最小提币数量：&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>
              {{ currencyInfo.withdraw_min_quantity }}
              {{ $route.params.type }}
            </span>
          </span>
        </a-form-model-item>
        <a-form-model-item>
          <p :style="{ display: 'flex' }">
            <span :style="{ position: 'relative' }"> 预估到账 </span>
            <span>
              {{ formModel.item.c | advanceCharge }}
              {{ $route.params.type }}
            </span>
          </p>
          <span slot="help" :style="{ color: '#acacac' }">
            (已扣除手续费)
          </span>
        </a-form-model-item>
        <password-enter
          :pwd.sync="formModel.item.d"
          :show.sync="passwordEnterShow"
          @ok="handleSubmitForm"
        />
        <a-form-model-item>
          <a-button
            id="submit-button"
            html-type="submit"
            @click="form_password_enter"
          >
            提交申请
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <div>
            <p>温馨提示：</p>
            <p :style="{ color: '#f5222d' }">
              1、为保障您的资金安全，请仔细核对所填信息，如因钱包地址信息不正确导致的资金损失，资金无法原路退回，我公司不负任何责任。
            </p>
            <p>2、恶意提交无效提币申请将封禁账号。</p>
            <p>3、手续费将从余额扣除，请预留手续费。</p>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { riskAlert } from "@/components";

import { Modal } from "ant-design-vue";
Vue.use(Modal);

import passwordEnter from "@/components/Password-Enter";

let that = {};
import "@/components/js/_form_common.js";
export default {
  components: {
    passwordEnter,
  },
  filters: {
    advanceCharge: (value) => {
      let a = value * (that.currencyInfo.withdraw_brokerage / 100);
      const b = that.currencyInfo.withdraw_brokerage_toplimit;
      if (b && b < a) a = that.currencyInfo.withdraw_brokerage_toplimit;
      a = that.formModel.item.c - a;
      return a.toFixed(6);
    },
  },
  // 数据
  data() {
    that = this;
    return {
      formModel: {
        item: {
          a: "火币网", // 交易平台
          b: "", // 地址
          c: 0, // 数量
          d: "", // 密码
        },
        rules: {
          c: [
            {
              validator: (rule, value, callback) => {
                if (value < this.currencyInfo.min_quantity) {
                  this.formModel.item.c = this.currencyInfo.min_quantity;
                  callback(`最小数量不能低于${this.currencyInfo.min_quantity}`);
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
        },
      },
      passwordEnterShow: false,
      currencyInfo: {},
    };
  },
  // 方法
  methods: {
    form_password_enter() {
      this.$message.destroy();
      if (this.formModel.item.c < this.currencyInfo.withdraw_min_quantity) {
        this.$message.warning("您提现资产数量小于最低提现数量");
        this.formModel.item.c = this.currencyInfo.withdraw_min_quantity;
      }

      if (this.currencyInfo.balance < this.currencyInfo.withdraw_min_quantity) {
        this.$message.warning("您当前可提现资产不足最小提币数量");
      } else {
        this.$refs["formModel"].validate((valid) => {
          if (valid) this.passwordEnterShow = true;
        });
      }
    },
    handleSubmitForm() {
      this.$refs["formModel"].validate((valid) => {
        if (valid)
          this.$api("withdraw", {
            currency: this.$route.params.type,
            tag: this.formModel.item.a,
            // address: this.formModel.item.b,
            quantity: parseFloat(this.formModel.item.c),
            pay_pwd: this.$axios.Base64.encode(this.formModel.item.d),
          });
      });
    },
    async getCurrencyInfo() {
      await this.$axios
        .get("personal-center/currency", {
          params: {
            currency: this.$route.params.type,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            const that = this;
            if (res.data.is_identity_authentication !== 1) {
              riskAlert(false);
              Modal.warning({
                title: `您还没有进行实名认证`,
                icon: "warning",
                okText: "立即设置",
                onOk() {
                  that.$router.push({
                    name: "Real-Name-Authentication",
                  });
                },
              });
              return;
            }

            if (!res.data.withdraw_switch) {
              riskAlert(false);
              Modal.warning({
                title: `系统清算中`,
                content: "目前不支持提币操作",
                icon: "warning", // <a-icon type="warning" />
                okText: "确认",
                onOk() {
                  that.$router.back(-1);
                },
              });
              return;
            } else if (this.currencyInfo.min_quantity)
              this.formModel.item.c = this.currencyInfo.min_quantity;

            this.currencyInfo = res.data;
          } else this.$message.error(res.message);
        });
    },
    input_num_control() {
      let num = 0;
      const a = this.formModel.item.c ? parseFloat(this.formModel.item.c) : 0;
      const b = parseFloat(this.currencyInfo.balance);
      // 判断输入数量是否小于 当前账户 可提现资产
      num = a === 0 ? 0 : a <= b ? a : b;
      if (b < a) this.$message.warning(`您当前可提现最大数量为${b}`);

      this.formModel.item.c = num.toFixed(6);
    },
  },
  created() {
    riskAlert();
    this.getCurrencyInfo();
  },
};
</script>

<style lang="less" >
#Withdraw {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > * {
    width: 100%;
  }
}
</style>